import commonConfig, { getLpHost } from './common.config';

export default function stagingConfig() {
  const apiHost = 'https://api-test.leadpages.io';
  const lpHost = getLpHost({
    appspotHost: 'https://leadpage-test.appspot.com',
    allowedDomains: ['leadpagestest.com', 'leadpagestest.net'],
    defaultHost: 'my.leadpagestest.com',
  });
  return {
    ...commonConfig(apiHost, lpHost),
    ADSENSE_CLIENT_ID: '510274384657-a7lfnn95c8lv48t4aflem3ktggbjbone.apps.googleusercontent.com',
    CHEVRON: {
      ENV: 'DEVELOPMENT',
      storageUrl: `${lpHost}/sso`,
    },
    DEBUG: true,
    HUBSPOT_PORTAL_ID: '24139017',
    ENVIRONMENT: 'staging',
    FIREBASE_SERVICE_URL: 'https://lp-playground.firebaseio.com/',
    FULLSTORY_ORG_ID: null,
    LEADBOX_EMBED_SCRIPT_URL: 'https://embed.lpcontenttest.net/leadboxes/current/embed.test.js',
    MIXPANEL_TOKEN: 'd95eed30416356542c30ad81899df5bb',
    OBSERVATIONS_ENABLED: false,
    OPTIN_BACKUP_HOST: 'https://optin-snf-dot-leadpage-test.appspot.com',
    SENTRY_DSN: 'https://c47513774ab14cf4b634f4d68ea58971@sentry.io/1442296',
    USER_PUBLISH_DOMAIN: 'lpagestest.co',
    ZOPIM_KEY: '2CrzDCa9JqXmBcJY1G1AACvBEVK3Es1k',
    FORETHOUGHT_KEY: '0a8dac53-7465-4802-9d24-8d8efd41a076',
  };
}
